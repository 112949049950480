import { Outlet, Link,useNavigate } from "react-router-dom";

const Layout = () => {
  const navigate = useNavigate();
  const Logout=()=>{
    window.localStorage.clear();
    navigate('/');
    console.log("in nvabar"+sessionStorage.getItem("loggedIn"));
  }
  return (
    <><p>This is test</p>
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/blogs">Blogs</Link>
          </li>
          <li>
            <Link to="/About">About</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/" onClick={Logout}>Logout</Link>
          </li>
        </ul>
      </nav>

      <Outlet />
    </>
  )
};

export default Layout;