import { Outlet, Navigate} from 'react-router-dom';
function Protect(){    
    const isLoggedIn= localStorage.getItem("token");
    return isLoggedIn==="123"?<Outlet /> : <Navigate to="/Login" />
    
    //props.setIsAuthenticated(true);
    /*console.log("isAuthenticated = "+props.isAuthenticated);
    return (
        props.isAuthenticated ? <Outlet /> : <Navigate to="Login" /> 
    )*/
    
}
export default Protect;