import React, { useState, useEffect } from 'react';
import Dashboard from './Dashboard';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Layout from "./Layout";
import Home from "./Home";
import Blogs from "./Blogs";
import Contact from "./Contact";
import NoPage from "./NoPage";
import Login from "./Login";
import Protect from "./Protect";

function About(props) {
    const [isAuthenticated, setIsAuthenticated] = useState("raj");

    // Check login status on mount (for example, checking local storage for a token)
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
        }
    }, []);
    const Username=(props)=>{
        return "treri";
    }
    const myElement = <About brand="Ford" />;
    

    return (
        <>
        <p>Hello Alok</p>
        <p onClick={Username}>Alok</p>
        <p>Hello {isAuthenticated}</p>
        <h2>I am a { props.brand }!</h2>;
        </>
    );
}

export default About;
