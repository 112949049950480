import React, { useState, useEffect } from 'react';
import Dashboard from './components/Dashboard';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Blogs from "./components/Blogs";
import Contact from "./components/Contact";
import NoPage from "./components/NoPage";
import Login from "./components/Login";
import Protect from "./components/Protect";
import About from "./components/About";
//import About from "./components/Dashboard";
function App() {
    const isLoggedIn= localStorage.getItem("token");
    return (
      <BrowserRouter>
        <Routes>
            {!isLoggedIn && (
            <>
              <Route path="/" element={<Layout />}>
              <Route index element={<Login />} />
              <Route path="/Home" element={<Home />} />
              <Route path="/Login" element={<Login />} />
              </Route>
            </>
            )}
            <Route path="/" element={<Layout />}>
            <Route index element={<Login />} />           
            <Route path="/contact" element={<Contact />} /> 
            <Route path="*" element={<NoPage />} /> 
            <Route element={<Protect />}>
                <Route path="/Login" element={<Navigate to ="/blogs" />} />
                <Route path="/blogs" element={<Blogs  />} />
                <Route path="/About" element={<About  />} />
            </Route>
            </Route>
        </Routes>
    </BrowserRouter>
    );
}

export default App;
